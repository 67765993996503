import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Social from "./components/Social";
import Information from "./components/Information";
import ImportantLinks from "./components/ImportantLinks";

const Footer = () => {
  const { breakpoints } = useTheme();
  const isMdLayout = useMediaQuery(breakpoints.up("lg"));
  return (
    <Box sx={{ backgroundColor: "#00042c" }} id="about">
      <Grid container py={5} px={isMdLayout ? 15 : 5}>
        <Grid xs={12} md={4} item>
          <Social />
        </Grid>
        <Grid xs={12} md={4} item>
          <Information />
        </Grid>
        <Grid xs={12} md={4} item>
          <ImportantLinks />
        </Grid>
      </Grid>
      <Stack sx={{ borderTop: "2px solid #fff", p: 2 }}>
        <Typography
          variant="body2"
          sx={{ color: "#fff", fontWeight: 500, textAlign: "center" }}
        >
          © DoLogics 2023 | All rights reserved.
        </Typography>
      </Stack>
    </Box>
  );
};
export default Footer;
