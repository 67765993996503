import { Box, Stack, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialsSlot from "./components/TestimonialsSlot";
import { TestimonialsData } from "./components/TestimonialData";

const Testimonials = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1268 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1268, min: 942 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 942, min: 0 },
      items: 1,
    },
  };
  return (
    <Box id="testimonials" p={2} my={3}>
      <Typography
        variant="h5"
        my={4}
        sx={{ textAlign: "center", color: "#25a9e0" }}
      >
        Testimonials
      </Typography>

      <Carousel
        showDots={true}
        removeArrowOnDeviceType={["tablet", "mobile", "laptop", "desktop"]}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        responsive={responsive}
      >
        {TestimonialsData?.map((item, index) => {
          return (
            <Stack
              key={index}
              sx={{ margin: "3rem auto", alignItems: "center" }}
            >
              <TestimonialsSlot data={item} />
            </Stack>
          );
        })}
      </Carousel>
    </Box>
  );
};
export default Testimonials;
