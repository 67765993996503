import { FacebookOutlined, Instagram, LinkedIn } from "@mui/icons-material";
import {
  Avatar,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "assets/icons/logo.png";

const Social = () => {
  const { breakpoints } = useTheme();
  const isMdLayout = useMediaQuery(breakpoints.up("md"));
  return (
    <Stack mb={isMdLayout ? 0 : 4}>
      <img
        src={logo}
        alt={"DoLogics"}
        style={{
          width: "250px",
          objectFit: "contain",
        }}
        loading="lazy"
      />
      <Typography variant="body2" sx={{ color: "#fff", my: 2 }}>
        Stay Connected with us on social media for the latest updates. Our team
        of software experts are always pushing the boundaries of what's
        possible. follow us to see how!
      </Typography>
      <Stack
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {socialList?.map((item, index) => {
          const Icon = item?.icon;
          return (
            <Link key={index} href={item?.link} target="_blank">
              <Avatar
                variant="circular"
                sx={{ bgcolor: "#25a9e0", mr: 1, cursor: "pointer" }}
              >
                <Icon sx={{ width: "25px", height: "24px" }} />
              </Avatar>
            </Link>
          );
        })}
      </Stack>
    </Stack>
  );
};
export default Social;

const socialList = [
  { icon: FacebookOutlined, link: "https://www.facebook.com/Dologics" },
  { icon: LinkedIn, link: "https://www.linkedin.com/company/dologics/" },
  { icon: Instagram, link: "https://www.instagram.com/dologicss/" },
];
