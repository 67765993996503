import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CuddleClone from "assets/projects/CC.png";
import CoBuilder from "assets/projects/coBuilder.png";
import exoDexa from "assets/projects/exoDexa.png";
import flowbarExtension from "assets/projects/flowbar-extension.png";
import idrak from "assets/projects/idrak.png";
import OptimaCRM from "assets/projects/OptimaCRM.png";
import myLobby from "assets/projects/myLobby.png";
import project from "assets/projects/project.png";
import rabbitCare from "assets/projects/rabbitCare.png";
import skyLead from "assets/projects/skyLead.png";
import Talamus from "assets/projects/Talamus.png";
import talkSay from "assets/projects/talkSay.png";
import vocatech from "assets/projects/vocatech.png";
import { Box, Stack, Typography } from "@mui/material";

const Projects = () => {
  const projectsImages = [
    { logo: CuddleClone, attr: "CuddleClone" },
    { logo: CoBuilder, attr: "CoBuilder" },
    { logo: exoDexa, attr: "exoDexa" },
    { logo: flowbarExtension, attr: "flowbar Extension" },
    { logo: idrak, attr: "idrak" },
    { logo: myLobby, attr: "My Lobby" },
    { logo: OptimaCRM, attr: "CRM" },
    { logo: project, attr: "project" },
    { logo: rabbitCare, attr: "Rabbit Care" },
    { logo: skyLead, attr: "Sky Lead" },
    { logo: Talamus, attr: "Talamus" },
    { logo: talkSay, attr: "talkSay" },
    { logo: vocatech, attr: "vocatech" },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  return (
    <Box id="projects" p={2}>
      <Typography
        variant="h5"
        my={4}
        sx={{ textAlign: "center", color: "#25a9e0" }}
      >
        Our Projects
      </Typography>
      <Carousel
        showDots={true}
        removeArrowOnDeviceType={["tablet", "mobile", "laptop", "desktop"]}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        responsive={responsive}
      >
        {projectsImages?.map((info, index) => {
          return (
            <Stack
              key={index}
              sx={{ margin: "3rem auto", alignItems: "center" }}
            >
              <img src={info.logo} width="100" alt={info.attr} />
            </Stack>
          );
        })}
      </Carousel>
    </Box>
  );
};
export default Projects;
