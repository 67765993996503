import client5 from "assets/clients/adam.png";
import client6 from "assets/clients/tina.png";
import client9 from "assets/clients/sasha.jpg";
import client13 from "assets/clients/Tejas-Shyani.jpg";

export const TestimonialsData = [
  {
    icon: client13,
    name: "Tejas Shyani",
    comment:
      "The team at DoLogics was professional and timely, building the site in a cost-effective manner. Because of the site's high-quality, the co-founder likes to show it off. ",
  },
  {
    icon: client9,
    name: "Sasha Shtern",
    comment:
      "DoLogics successfully delivered the mobile app. Their team impressed the client with their genuine care for the project. They also ensured effective communication through daily standups.",
  },
  {
    icon: client6,
    name: "Tina Ghanem",
    comment:
      "I worked with Muhammad Arslan and his team at DoLogics on several projects. He and his team have been fantastic to work with; they deliver on time and with a high level of quality. In addition, they are very organized and provide daily updates. I was delighted with all my engagements with Arslan and his team and highly recommend them.",
  },
  {
    icon: client5,
    name: "Adam Greene",
    comment:
      "Team DoLogics has been fantastic to work with. He has a CTO's acumen and a work ethic to match. I would highly recommend considering him and his team for your Development needs.",
  },
];
