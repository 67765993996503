import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Banner from "assets/banner/bg.png";

const { default: Header } = require("components/Header/Header");

const HeaderSection = () => {
  const { breakpoints } = useTheme();
  const isSmLayout = useMediaQuery(breakpoints.up("md"));
  return (
    <>
      <Header />
      <Box
        id="home"
        sx={{ width: "100%", height: "100vh", position: "relative" }}
      >
        <img
          src={Banner}
          alt="banner"
          style={{ width: "100%", height: "100%" }}
        />
        <Stack
          sx={{
            position: "absolute",
            top: "0%",
            width: "100%",
            height: "100%",
            backgroundColor: "#40404180",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#fff" }} variant="h5">
            Top Rated Software Development Agency
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              width: isSmLayout ? "50%" : "90%",
              textAlign: "center",
              my: 2,
            }}
            variant="h1"
          >
            Custom Software Solutions That Drive Business Growth
          </Typography>
          <Typography
            sx={{ color: "#fff" }}
            variant="h4"
            px={isSmLayout ? 0 : 2}
          >
            Transforming Ideas into Profitable Digital Solutions
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
export default HeaderSection;
