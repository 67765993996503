import { Box, Grid, Typography } from "@mui/material";
import ServicesCard from "./components/ServicesCard";
import { ServicesData } from "./components/data";

const Services = () => {
  return (
    <Box mt={6} id={"services"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2" sx={{ color: "#25a9e0", fontWeight: 500 }}>
          The Value We Deliver
        </Typography>
        <Typography variant="h4">Our Professional Services For You</Typography>
        <Typography variant="body2">
          DoLogics is a one-stop shop for all your IT needs.
        </Typography>
      </Box>
      <Grid container p={2} mt={2} justifyContent={"space-between"}>
        {ServicesData?.map((item, index) => {
          return (
            <Grid
              item
              lg={3.9}
              md={5.9}
              xs={12}
              mb={2}
              key={index}
              sx={{ ".MuiPaper-root": { minHeight: "320px !important" } }}
            >
              <ServicesCard data={item} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default Services;
