import Logo from "assets/icons/logo.png";
import { styles } from "./HeaderStyles";
import { useEffect, useState } from "react";
import {
  Box,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import Drawer from "./Drawer";

const Header = () => {
  const location = useLocation();
  const pathHash = location?.hash || "";
  const { breakpoints } = useTheme();
  const isMdLayout = useMediaQuery(breakpoints.up("md"));
  const [isScroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    if (window.pageYOffset > 10) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  const getSelectedStyle = (id) => {
    return {
      ...styles.navLabel,
      borderBottom: pathHash === id ? "2px solid #25a9e0" : "none",
      color: isScroll ? "#000" : "#fff",
    };
  };
  return (
    <Stack sx={isScroll ? styles?.headerWrapperScroll : styles?.headerWrapper}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 5%",
        }}
      >
        <Stack>
          <img
            src={Logo}
            alt={"DoLogics"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            loading="lazy"
          />
        </Stack>

        <Stack>
          <Drawer isScroll={isScroll} />
          <Box sx={{ display: isMdLayout ? "flex" : "none", gap: 2 }}>
            <Link href="#home">
              <Typography
                variant="h6"
                sx={{
                  ...styles.navLabel,
                  borderBottom:
                    pathHash === "#home" || !pathHash
                      ? "2px solid #25a9e0"
                      : "none",
                  color: isScroll ? "#000" : "#fff",
                }}
              >
                Home
              </Typography>
            </Link>
            <Link href="#services">
              <Typography variant="h6" sx={getSelectedStyle("#services")}>
                Services
              </Typography>
            </Link>
            <Link href="#projects">
              <Typography variant="h6" sx={getSelectedStyle("#projects")}>
                Projects
              </Typography>
            </Link>
            <Link href="#testimonials">
              <Typography variant="h6" sx={getSelectedStyle("#testimonials")}>
                Testimonials
              </Typography>
            </Link>
            <Link href="#about">
              <Typography variant="h6" sx={getSelectedStyle("#about")}>
                About Us
              </Typography>
            </Link>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
export default Header;
