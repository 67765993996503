import { Avatar, Card, Rating, Typography } from "@mui/material";

const TestimonialsSlot = ({ data }) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        p: 3,
        width: "300px",
        height: "300px",
      }}
    >
      {data?.icon && (
        <Avatar src={data?.icon} sx={{ width: "75px", height: "75px" }} />
      )}
      <Rating name="read-only" value={5} readOnly />
      <Typography variant="h5">{data?.name}</Typography>
      <Typography variant="caption">{data?.comment}</Typography>
    </Card>
  );
};
export default TestimonialsSlot;
