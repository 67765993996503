import { Email, LocalPhone, LocationOnOutlined } from "@mui/icons-material";
import {
  Avatar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import pkFlag from "assets/icons/flags/pakistan.png";
import usFlag from "assets/icons/flags/usa.png";

const Information = () => {
  const { breakpoints } = useTheme();
  const isMdLayout = useMediaQuery(breakpoints.up("md"));
  return (
    <Stack pl={isMdLayout ? 10 : 0} mb={isMdLayout ? 0 : 4}>
      <Typography variant="h5" sx={{ color: "#fff" }} mb={1}>
        Information
      </Typography>
      {officesList?.map((element, i) => {
        return (
          <Stack key={i} sx={{ marginBottom: i === 0 ? "30px" : "0px" }}>
            {element?.map((item, index) => {
              const Icon = item?.icon;
              return (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  key={index}
                  mb={1}
                >
                  {index === 0 ? (
                    <Avatar
                      src={item.icon}
                      sx={{
                        width: "28px",
                        height: "28px",
                        mr: 1,
                        borderRadius: "0px",
                      }}
                    />
                  ) : (
                    <Icon
                      sx={{
                        color: "#fff",
                        width: "24px",
                        height: "24px",
                        mr: 1,
                      }}
                    />
                  )}
                  <Typography
                    sx={{ color: "#fff", fontWeight: 500 }}
                    variant={index === 0 ? "h5" : "body2"}
                  >
                    {item?.title}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};
export default Information;

const officesList = [
  [
    {
      icon: pkFlag,
      title: "Pakistan",
    },
    {
      icon: LocalPhone,
      title: "+923001122805",
    },
    {
      icon: Email,
      title: "dologics34@gmail.com",
    },
    {
      icon: LocationOnOutlined,
      title: "8 E-1 Johar Town, Lahore",
    },
  ],
  [
    {
      icon: usFlag,
      title: "USA — Head Office",
    },
    {
      icon: LocalPhone,
      title: "+14159150184",
    },
    {
      icon: Email,
      title: "dologics34@gmail.com",
    },
    {
      icon: LocationOnOutlined,
      title: "548 MARKET ST #468631 SAN FRANCISCO, CA 94104",
    },
  ],
];
