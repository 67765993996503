import web from "assets/technologies/web-development.png";
import mobile from "assets/technologies/mobile-app-development.png";
import eCommerce from "assets/technologies/ecommerce.png";
import erp from "assets/technologies/erp_and_crm.png";
import ai from "assets/technologies/ai.png";
import it from "assets/technologies/consultancy-services.png";

export const ServicesData = [
  {
    icon: web,
    title: "Web Development",
    des: `Whether you need a simple website or a complex web application, we can
    create it for you. With years of experience, our talented developers
    strategically integrate robust, scalable code architecture using modern
    technologies to deliver smooth UX, dynamic functionality, and assured
    performance.`,
  },
  {
    icon: mobile,
    title: "Mobile App Development",
    des: `Bring your innovative app ideas to life and engage users across platforms. Our expert mobile developers design, build, and launch intuitive, user-friendly iOS and Android apps that not only look amazing but also deliver real results and ROI through optimized functionality.`,
  },
  {
    icon: eCommerce,
    title: "Ecommerce & Shopify Development",
    des: `Open your brand to new markets and start selling anywhere, anytime. We specialize in designing and developing custom ecommerce stores on Shopify and other leading platforms that convert visitors into loyal customers. Beautifully designed, mobile-optimized, and featuring seamless checkout.`,
  },
  {
    icon: erp,
    title: "ERP & CRM Development",
    des: `Streamline operations and gain critical insights with a custom ERP or CRM solution tailored to your unique needs. Our solutions centralize data and automate processes to increase efficiency, productivity, and informed decision making across departments. Built to scale, drive growth, and facilitate collaboration.`,
  },
  {
    icon: ai,
    title: "AI Development",
    des: `Unlock transformative capabilities through AI-powered tools. We build and integrate smart machine learning models and algorithms that extract hidden insights, enable predictive capabilities, and drive intelligent automation to optimize workflows and enhance data-driven decision making.`,
  },
  {
    icon: it,
    title: "Strategic IT Consulting",
    des: `DoLogics provides consultancy services to various businesses to help them automate and digitize their processes, optimize their software portfolio, and implement the latest technologies. We have the expertise and experience to guide you through every step of your digital transformation journey.`,
  },
];
