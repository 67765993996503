import Footer from "./components/footer/Footer";
import HeaderSection from "./components/header/Header";
import Projects from "./components/projects/Projects";
import Services from "./components/services/Services";
import Testimonials from "./components/testimonials/Testimonials";

const Home = () => {
  return (
    <>
      <HeaderSection />
      <Services />
      <Projects />
      <Testimonials />
      <Footer />
    </>
  );
};
export default Home;
