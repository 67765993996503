import {
  Box,
  Drawer,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useState } from "react";

const DrawerComponent = ({ isScroll }) => {
  const [open, setOpen] = useState(false);
  const { breakpoints } = useTheme();
  const isMdLayout = useMediaQuery(breakpoints.up("md"));
  return (
    <>
      <Menu
        sx={{
          width: "30px",
          height: "30px",
          color: isScroll ? "#000" : "#fff",
          display: isMdLayout ? "none" : "block",
        }}
        onClick={() => setOpen(true)}
      />
      <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{ width: "250px", py: 3 }}
          role="presentation"
          onClick={() => setOpen(false)}
        >
          {linksData?.map((item, index) => {
            return (
              <Link href={item?.key} key={index}>
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid #E6E6E7",
                    px: 3,
                    py: 0.5,
                  }}
                >
                  {item?.name}
                </Typography>
              </Link>
            );
          })}
        </Box>
      </Drawer>
    </>
  );
};
export default DrawerComponent;

const linksData = [
  {
    name: "Home",
    key: "#home",
  },
  {
    name: "Services",
    key: "#services",
  },
  {
    name: "Projects",
    key: "#projects",
  },
  {
    name: "Testimonials",
    key: "#testimonials",
  },
  {
    name: "About Us",
    key: "#about",
  },
];
