export const styles = {
  headerWrapper: {
    backgroundColor: "transparent",
    marginTop: "20px",
    position: "fixed",
    top: 0,
    zIndex: 100,
    width: "100% !important",
  },
  headerWrapperScroll: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    zIndex: 100,
    width: "100% !important",
    boxShadow: "-4px 5px 30px -4px rgba(133, 133, 133, 0.59)",
    WebkitBoxShadow: "-4px 5px 30px -4px rgba(133, 133, 133, 0.59)",
    MozBoxShadow: "-4px 5px 30px -4px rgba(133, 133, 133, 0.59)",
  },
  navLabel: {
    ":hover": {
      borderBottom: "2px solid #25a9e0",
    },
  },
};
