import {
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const ImportantLinks = () => {
  const { breakpoints } = useTheme();
  const isMdLayout = useMediaQuery(breakpoints.up("md"));
  return (
    <Stack pl={isMdLayout ? 10 : 0}>
      <Typography variant="h5" sx={{ color: "#fff" }} mb={1}>
        Important Links
      </Typography>
      {linksData?.map((item, index) => {
        return (
          <Link href={item?.key} key={index}>
            <Typography variant="h6" sx={{ color: "#fff" }}>
              {item?.name}
            </Typography>
          </Link>
        );
      })}
    </Stack>
  );
};
export default ImportantLinks;

const linksData = [
  {
    name: "Home",
    key: "#home",
  },
  {
    name: "Services",
    key: "#services",
  },
  {
    name: "Projects",
    key: "#projects",
  },
  {
    name: "Testimonials",
    key: "#testimonials",
  },
  {
    name: "About Us",
    key: "#about",
  },
];
