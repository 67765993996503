import { Avatar, Card, Typography } from "@mui/material";

const ServicesCard = ({ data }) => {
  const { icon, title, des } = data;
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        p: 3,
      }}
    >
      {icon && <Avatar src={icon} sx={{ width: "60px", height: "60px" }} />}
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body2">{des}</Typography>
    </Card>
  );
};
export default ServicesCard;
